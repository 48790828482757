@import '../../common/components/USBTableCopy/styles/index.scss';
@import '../../common/components/USBTableCopy/table-sort/src/styles/index.scss';
@import '@usb-shield/design-tokens/dist/responsive-web/consumer/consumer.scss';

.dashboard {
  .col {
    .usb-breadcrumb {
      margin-bottom: $token-spacing-8;
    }
  }

  .tabs__nav {
    margin-bottom: $token-spacing-8;
  }

  .table.auto {
    tr th {
      border: none;
      color: $token-color-grey-90;
    }

    tr th:nth-child(n + 2) {
      text-align: right;
    }

    tr th.table-header-tooltip {
      max-width: none;
      justify-content: flex-end;
    }

    tr:nth-child(odd) {
      background: $token-background-primary;
    }

    td[class^='table-cell-'] {
      border: none;
      text-align: right;
    }
    td.table-cell-0 {
      text-align: left;
    }

    .table-sort__container {
      display: table-row;
    }
    
    @media only screen and (max-width: $token-breakpoint-medium) {
      .table-sort__container {
        display: none;
      }
      
    }
  }

  .table-header-tooltip {
    display: flex;

    span {
      font-weight: 500;
    }

    span.table-tooltip {
      margin-top: -3px;
      margin-left: $token-spacing-1;

      .tooltip__content {
        text-wrap: wrap;

        .tooltip-text-block {
          display: flex;
          text-align: left;
        }
      }
    }
  }
}
